<template>
  <div>
    <title-bar :title="$t('navigations.variables')"/>
    <div v-if="variables" class="custom-table">
      <table-actions
        :actions="['addBtn', 'perPage']"
        :addBtnText="$t('variable.add')"
        :addBtnLink="$helper.getAddUrl('variable')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="variables"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:name="data">
          <router-link :to="$helper.getEditUrl('variable', data.value.id)">
            {{ data.value.name }}
          </router-link>
        </template>
        <template v-slot:tag="data">
          {{ data.value.tags.join(', ') }}
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('variable', data.value.id)"/>
            <delete-btn @pressDelete="deleteVariable(data.value['@id'])"/>
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-if="pages"
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar
  },
  mixins: [ResourceUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      variables: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t('variable.name'),
          field: "name",
          sortable: false,
        },
         {
          label: this.$t('forms.tags'),
          field: "tag",
          sortable: false,
        },
        {
          label: this.$t('variable.actions'),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadVariables();
  },
  methods: {
    loadVariables() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        'order[fromDate]': 'desc' 
      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Variables.getCollection({params}).then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.variables = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadVariables();
    },
    deleteVariable(url) {
      this.deleteByUrl(this.$Variables, url, this.$t('variable.deleted'), null, this.loadVariables, this.error);
    },
    error() {
      this.$emit("clearAction");
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadVariables();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadVariables();
    },
    onPageChange() {
      this.loadVariables();
    },
  },
};
</script>
